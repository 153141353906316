<template>
  <div class="card">
    <Toast />
    <h5>Banners</h5>
    <DataTable :value="banners" class="p-datatable-gridlines" dataKey="id" :rowHover="true" filterDisplay="menu"
      :loading="loading" responsiveLayout="scroll">
      <template #header>
        <Button type="button" icon="pi pi-plus" label="Criar Banner" class="p-button p-component p-button-success "
          @click="redirecionarParaEdicao()" />
      </template>
      <template #empty>
        Nenhum registro encontrado.
      </template>
      <template #loading>
        Carregando dados. Por favor, aguarde!
      </template>

      <Column field="titulo" header="Título" style="width: 10rem;">
        <template #body="{ data }">
          <span>{{ data.titulo }}</span>
        </template>
      </Column>
      <Column field="descricao" header="Descrição" style="width: 15rem;">
        <template #body="{ data }">
          <span>{{ data.descricao }}</span>
        </template>
      </Column>
      <Column field="path" header="Path" style="width: 10rem;">
        <template #body="{ data }">
          <span>{{ data.path }}</span>
        </template>
      </Column>
      <Column field="imagem" header="Imagem" style="width: 10rem;">
        <template #body="{ data }">
          <div v-if="data.imagem" style="margin-top: 5px;">
            <img :src="getImageSrc(data.imagem)" alt="Preview" style="max-width: 100px; max-height: 100px;" />
          </div>
        </template>
      </Column>
      <Column field="textoBotao" header="Texto do Botão" style="width: 10rem;">
        <template #body="{ data }">
          <span>{{ data.textoBotao }}</span>
        </template>
      </Column>

      <Column field="ativo" header="Ativo" style="width: 5rem;">
        <template #body="{ data }">
          <Button :label="data.ativo ? 'Ativado' : 'Desativado'"
            :class="data.ativo ? 'p-button-success' : 'p-button-danger'" @click="updateBannerStatus(data)"
            style="width: 100%; text-align: center;" />
        </template>
      </Column>

      <Column style="width: 4rem; text-align: center;">
        <template #body="{ data }">
          <div v-if="!isCreating" class="flex align-items-center justify-content-center">
            <Button icon="pi pi-pencil" class="p-button-text" @click="redirecionarParaEdicao(data)" />
            <Button icon="pi pi-trash" class="p-button-danger p-button-text" @click="confirmarExclusao(data)" />
          </div>
        </template>
      </Column>
    </DataTable>

    <Dialog v-model:visible="confirmDeleteDialog" :style="{ width: '300px' }" header="Confirmar Exclusão" :modal="true">
      <div class="flex align-items-center justify-content-center">
        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
        <span>Tem certeza de que deseja excluir este banner?</span>
      </div>
      <template #footer>
        <Button label="Não" icon="pi pi-times" class="p-button-text" @click="confirmDeleteDialog = false" />
        <Button label="Sim" icon="pi pi-check" class="p-button-text p-button-danger" @click="excluirBanner()" />
      </template>
    </Dialog>
  </div>
</template>

<script>
import BannerService from '../../service/BannerService';

export default {
  name: 'Banners',
  data() {
    return {
      banners: [],
      loading: false,
      confirmDeleteDialog: false,
      bannerToDelete: null,
    };
  },
  created() {
    this.carregarBanners();
  },
  methods: {
    getImageSrc(base64) {
      return `data:image/jpeg;base64,${base64}`;
    },

    confirmarExclusao(banner) {
      this.bannerToDelete = banner;
      this.confirmDeleteDialog = true;
    },

    redirecionarParaEdicao(banner) {
      this.$router.push({
        path: `/editar-banner`,
        state: {
          data: {
            ...banner
          }
        }
      });
    },

    updateBannerStatus(banner) {
      this.loading = true;
      banner.ativo = !banner.ativo;
      BannerService.atualizar(banner)
        .then(() => {
          this.loading = false;
          this.$toast.add({
            severity: "success",
            detail: "Banner atualizado com sucesso!",
            life: 5000
          });
        })
        .catch(() => {
          this.loading = false;
          this.$toast.add({
            severity: "error",
            detail: "Falha ao atualizar o banner!",
            life: 5000
          });
        });
    },

    carregarBanners() {
      this.bannerToDelete = null;
      this.confirmDeleteDialog = false;
      this.banners = [];
      this.loading = true;

      BannerService.carregarBanners()
        .then(({ data }) => {
          this.banners = data?.sort?.((a, b) => b?.ativo - a?.ativo);
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
          this.$toast.add({
            severity: "error",
            detail: "Falha ao carregar os banners!",
            life: 5000
          });
        });
    },

    excluirBanner() {
      this.loading = true;
      BannerService.excluir(this.bannerToDelete.id)
        .then(() => {
          this.carregarBanners();
          this.$toast.add({
            severity: "success",
            detail: "Banner excluído com sucesso!",
            life: 5000
          });
        })
        .catch(() => {
          this.loading = false;
          this.confirmDeleteDialog = false;
          this.$toast.add({
            severity: "error",
            detail: "Falha ao excluir o banner!",
            life: 5000
          });
        });
    }
  }
};
</script>