<template>
  <Banner :matricula="currentUser?.matricula" />
</template>

<script lang="js">
import Banner from '../../components/cadastro/Banner.vue';

export default {
  computed: {
    currentUser() {

      return this.$store.state.auth.user;

    }
  },

  components: { Banner }
};
</script>
